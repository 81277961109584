import axios from "axios";
//https://gateway.dgjx0769.com
//https://api.lzzhtc.com/
// const baseURL = 'https://api.lzzhtc.com/'
const baseURL = 'https://apiv2.dgjx0769.com'
const request = axios.create({
  baseURL, //https://screen.dgjx0769.com',
  // baseURL: 'https://gateway.dgjx0769.com/', //https://screen.dgjx0769.com',
  timeout: 100000,
});

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);

export default request;
