import React from 'react';
import styles from './index.module.scss';
interface AButtonProps {
    iframeURL: string,
    text:string
  }

const AButton:React.FC< AButtonProps> = (props) => {
  return (
    <div className={styles.abtn_box}>
           <a className={`${styles.btn_diamond} ${props.text?  styles.btn_bagcolor:''}`} href={props.iframeURL} rel="noreferrer" target="_blank" title="查看详情">{props.text}</a>
    </div>
   
  )
}

export default AButton;