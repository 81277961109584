import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { observer } from "mobx-react";
import styles from "./style.module.scss";
import { useRequest } from "ahooks";
import { GetAreaStaticData } from "@/services/api";
import imgUrl from "@/assets/img/resource/area_total_bg.png";
import tipleft from "@/assets/img/tipleft.png";
import tipright from "@/assets/img/tipright.png";
import { objValueToInt } from "@/utils/parse";

import { useInterval } from 'ahooks';
const AreaBox: React.FC<{
  cityCode?: string;
  areaCode?: string;
  ZoomLevel: number;
}> = ({ ZoomLevel, areaCode, cityCode }) => {
  const { data: areaDataList = [], run: getAreaStaticDataRequest } = useRequest(
    useCallback(() => {
      return GetAreaStaticData("510500");
    }, []),
    {
      formatResult: (res) => {
        let retrunval = res.data ? res.data : [];

        let changedata: any[] = retrunval;
        let a = changedata.pop();
        changedata.unshift(a);
        return res.data ? changedata : [];
      },
        refreshDeps: []
    }
  );

  const intValueData = objValueToInt(areaDataList); // 将值转成整数
  const showAreaDataList = [
    {
      areaName: "龙马潭区",
      berthTotal: 3251,
      incomeTotal: 169852,
      roadTotal: 369852,
      parkTotal: 369852,
    },
    {
      areaName: "江阳区",
      berthTotal: 3251,
      incomeTotal: 569852,
      roadTotal: 369852,
      parkTotal: 369852,
    },
    {
      areaName: "纳溪区4",
      berthTotal: 3251,
      incomeTotal: 369852,
      roadTotal: 369852,
      parkTotal: 369852,
    },
  ];
  let datalist = areaDataList.length > 0 ? intValueData : showAreaDataList;

  useInterval(() => {
    getAreaStaticDataRequest()
  }, 180000)

  return (
    <div className={`${styles.data_box}`}>
      {datalist.map((item: object, index: number) => (
        <div className={styles.data_box_item} key={`${index}`}>
          {index == 0 ? (
            <div className={styles.item_piot}>
              <img src={tipright} alt="" />
            </div>
          ) : (
            <div className={styles.item_piot}>
              <img src={tipleft} alt="" />
            </div>
          )}
          {index == 3 ? (
            <div className={styles.item_title}>
              <i></i>
              {item["areaName"]}{" "}
            </div>
          ) : (
            <div className={styles.item_title}>
              {item["areaName"]} <i></i>
            </div>
          )}

          <div className={styles.item_content}>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>总收入(元)：</div>
              <div className={styles.data_tamt}>{item["incomeTotal"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>总车位(个)：</div>
              <div className={styles.data_tamt}>{item["berthTotal"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>道路车位(个)：</div>
              <div className={styles.data_tamt}>{item["roadTotal"] || 0}</div>
            </div>
            <div className={styles.item_box}>
              <div className={styles.item_data_text}>路外车位(个)：</div>
              <div className={styles.data_tamt}>{item["parkTotal"] || 0}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(AreaBox);
