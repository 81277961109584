import React, { useState, useEffect, useCallback, forwardRef,useMemo, useImperativeHandle } from 'react';
import TopTale from '@/components/topTable';
import { Modal,Image ,Pagination ,Table } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { useRequest } from 'ahooks';
import { Spin } from 'antd'
import {  GetPlatedeck,GetPlatesensitive,GetPlatetraffic ,GetPlatezombie,Getroadirregularparking,GetInspectorgReport,GetUnknownOrderlist} from '@/services/api';
import { observer } from 'mobx-react';
import styles from './index.module.scss';
import { number } from 'echarts';

export type AlarmType = 1 | 2 | 3 | 4 | 5 | 6 ;

export type OnAlarmClick = (type: AlarmType) => void;

const WarningModal:React.FC<{
  visible: boolean;
  onShowIframe: () => void;
  onClose: () => void;
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
  areaCode?: string;
  ref?: any;
}> = forwardRef(({visible,areaCode,onShowIframe,onClose,onAlarmFocus},ref) => {
  const [alarmType,setAlarmType] = useState<AlarmType | null>(null);
  const [pageNum,setPageNum] = useState('1');
  const [data,setdata] = useState([]);
  const [pagetotal,setPagetotal] = useState(0);
  
  const deviceTypename=(val:number)=>{
    return ({0:'未知',1:'地磁', 2:"低位", 3:'中位' ,4:'高位' }[val]);
  }
    
  const orderStatus=(val:number)=>{
    return ({1:'进行中',  3:'待缴费' ,5:'已缴费' }[val]);
  }

  const alarmTypeNames = {
    1: '设备报警1',
    2: '人员上报事件',
    3: '套牌车报警',
    4: '僵尸车报警',
    5: '敏感车报警',
    6: '未知订单量',
  
  }
  const alarmTypecolumns=[
    //设备报警
    [ { title: '序号',width:'80px', render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
    { title: '设备编号' , dataIndex: 'deviceCode'},
    { title: '设备类型' , dataIndex: 'deviceTypeLabel'},
    { title: '报警类型',  dataIndex: 'typeLabel'},
    { title: '停车场/道路名称' ,  render:(text: any,record: any)=>`${record.roadSectionName||''}/${record.parkName||''}`},
    { title: '出入口/泊位号' , render:(text: any,record: any)=>`${record.channelNo||''}/${record.berthNo||''}`},
    { title: '报警时间' , dataIndex: 'alarmTime'},
    { title: '照片' ,render:(text: any,record: any)=>( <Image
      width={80}
      src={record.imageFile}
    />)},
  ],
  //不规范停车改成人员上报事件
  [{ title: '序号' ,width:'80px', render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
  { title: '事件类型' , dataIndex: 'dictName'},
  { title: '上报内容' , dataIndex: 'content'},
  { title: '上报者' , dataIndex: 'reporterName'},
  { title: '上报时间' , dataIndex: 'createTime'},
  { title: '停车场/路段名称' , dataIndex: 'roadSectionName',width:'120px',render:(text: any,record: any)=>`${record.roadSectionName||''}`},
  { title: '出入口/泊位号' , dataIndex: 'parkingNo'},
  { title: '车牌号' , dataIndex: 'plateNo'},
  { title: '车牌颜色' , dataIndex: 'colorLabel'},
  { title: '照片' , dataIndex: 'photoOne',render:(text: any,record: any)=>( <Image
    width={60}
    src={record.photoOne}
  />)},
  { title: '事件处理内容' , dataIndex: 'handleContent'},
  { title: '是否上报监管部门' , dataIndex: 'isReportLabel',width:'120px'},
  { title: '状态' , dataIndex: 'statusLabel'},
  
  ],
  //套牌车报警
  [{ title: '序号'  ,width:'80px', render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
  { title: '车牌号码' , dataIndex: 'plateNo'},
  { title: '车主' , dataIndex: 'name'},
  { title: '手机号码' , dataIndex: 'mobile'},
  { title: '套牌照片1' , dataIndex: 'deckPhoto',render:(text: any,record: any)=>( <Image
    width={60}
    src={record.deckPhoto}
  />)},
  { title: '泊位/车场1' , render:(text: any,record: any)=>`${record.parkingNo||''}/${record.parkName||''}`},
  { title: '套牌照片2' , dataIndex: 'deckPhotoTwo',render:(text: any,record: any)=>( <Image
    width={60}
    src={record.deckPhotoTwo}
  />)},
  { title: '泊位/车场2' , render:(text: any,record: any)=>`${record.parkingNoTwo||''}/${record.parkNameTwo||''}`},
  ],
  //僵尸车报警
  [{ title: '序号' ,width:'80px' , render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
  { title: '车牌号码' , dataIndex: 'plateNo'},
  { title: '车主' , dataIndex: 'name'},
  { title: '手机号码' , dataIndex: 'mobile'},
  { title: '照片' , dataIndex: 'photo',render:(text: any,record: any)=>( <Image
    width={60}
    src={record.photo}
  />)},
  { title: '泊位/停车场' , render:(text: any,record: any)=>`${record.parkingNo||''}/${record.parkName||''}`},
  
  ],
  [{ title: '序号' ,width:'80px' , render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
  { title: '车牌号' , dataIndex: 'plateNo'},
  { title: '车主姓名' , dataIndex: 'name'},
  { title: '电话号码' , dataIndex: 'mobile'},
  { title: '照片' , dataIndex: 'photo',render:(text: any,record: any)=>( <Image
    width={60}
    src={record.photo}
  />)},
  { title: '泊位/停车场' , render:(text: any,record: any)=>`${record.parkingNo||''}/${record.parkName||''}`},
  { title: '入位时间' , dataIndex: 'driveInTime'},
  { title: '离位时间' , dataIndex: 'driveOutTime'},
  ],
  //未知订单
  [{ title: '序号'  ,width:'80px', render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
  { title: '订单号' , dataIndex: 'id'},
  { title: '驶入时间' , dataIndex: 'driveInTime'},
  { title: '驶出时间' , dataIndex: 'driveOutTime'},
  { title: '停车时长' , dataIndex: 'parkTimeLabel'},
  { title: '车牌号' , dataIndex: 'plateNo'},
  { title: '泊位号' , dataIndex: 'berthNo'},
  { title: '订单来源' , dataIndex: 'creator' },
  { title: '停车原费用' , dataIndex: 'fee'},
  { title: '实收停车费用' , dataIndex: 'totalFee',width:'100px'},
  { title: '道路名' , dataIndex: 'roadName'},
  { title: '路段名' , dataIndex: 'roadSectionName'},
  { title: '状态' , dataIndex: 'orderStatus',render:(text: any,record: any,index: number)=>orderStatus(record.orderStatus)},
  { title: '支付时间' , dataIndex: 'payTime' , render:(text: any,record: any)=>`${record.payTime||''}`},
  ]
  ]

  // const { data:roadirregularData, loading,run: getroadirregularList } = useRequest(useCallback(() => {
  //   return Getroadirregularparking({
  //     pageNum,
  //     pageSize:'15',
  //     areaCode
  //   })},[pageNum,areaCode]), {
  //     manual: true,
  //     formatResult: res =>{
  //       setPagetotal(res.data.total)
  //       return res.data.list
  //      } ,
  // })
  const { data:InspectorgReportData, loading,run: getInspectorgReportList } = useRequest(useCallback(() => {
    return GetInspectorgReport({
      pageNum,
      pageSize:'15',
      areaCode
    })},[pageNum,areaCode]), {
      manual: true,
      formatResult: res =>{
        setPagetotal(res.data.total)
        return res.data.list
       } ,
  })
  const { data:deckData, loading:loading2,run: getPlatedeckList } = useRequest(useCallback(() => {
    return GetPlatedeck({
      pageNum,
      pageSize:'15'
    })},[pageNum]), {
      manual: true,
     formatResult: res =>{
     setPagetotal(res.data.total)
     return res.data.list
    } ,
  })
  const { data:sitiveData,loading:loading3, run: getPlatesensitiveList } = useRequest(useCallback(() => {
    return GetPlatesensitive({
      pageNum,
      pageSize:'15'
    })},[pageNum]), {
      manual: true,
      formatResult: res =>{
        setPagetotal(res.data.total)
        return res.data.list
       } ,
  })
  const { data:trafficeData,loading:loading4, run: getPlatetrafficList } = useRequest(useCallback(() => {
    return GetPlatetraffic({
      pageNum,
      pageSize:'15'
    })},[pageNum]), {
      manual: true,
      formatResult: res =>{
        setPagetotal(res.data.total)
        return res.data.list
       } ,
  })
  const { data:zombieData,loading:loading5, run: getPlatezombieList } = useRequest(useCallback(() => {
    return GetPlatezombie({
      pageNum,
      pageSize:'15'
    })},[pageNum]), {
      manual: true,
      formatResult: res =>{
        setPagetotal(res.data.total)
        return res.data.list
       } ,
  })
  const { data:UnknownOrderData,loading:loading6, run: getUnknownOrder } = useRequest(useCallback(() => {
    return GetUnknownOrderlist({
      areaCode,
      pageNum,
      pageSize:'15',
    })},[pageNum,areaCode]), {
      manual: true,
      formatResult: res =>{
        setPagetotal(res.data.total)
        return res.data.list
       } ,
  })
  
  const onAlarmClick = (type: AlarmType,change:number) => {
    setPagetotal(0)
    if(!change){
      setPageNum('1')
    }
    setAlarmType(type);
    if(type==1){
      getPlatetrafficList()
    }
    if(type==2){
      getInspectorgReportList()
    }
    if(type==3){
      getPlatedeckList()
    }
    if(type==4){
      getPlatezombieList()
    }
    if(type==5){
      getPlatesensitiveList()
    }
    if(type==6){
      getUnknownOrder()
    }
  }

  useEffect(() => {
    if(!visible) {
      setAlarmType(null);
      setPagetotal(0)
      setPageNum('1')
    }
  },[visible])

  useImperativeHandle(ref, () => ({
    onAlarmClick
  }))
  const onChange = (page: any, pageSize: any)=>{
    setPageNum(page)
    setTimeout(() => {
      onAlarmClick(alarmType as AlarmType,1)
    }, 300);
    
  }
 
  return (
    <Modal
      className={styles.alarm_modal}
      width={1350}
      visible={visible}
      maskClosable={false}
      onCancel={onClose}
      closeIcon={<img src="/img/icon_close.png" alt="" className={styles.alarm_close} />}
      maskStyle={{background: 'transparent'}}
    >
      <div className={styles.warn_list_box}>
        <div className={styles.warn_tab_title}>
          {alarmTypeNames[alarmType as AlarmType]}
        </div>
        <div className={styles.warn_tale_box}>

        {loading||loading2||loading3||loading4||loading5||loading6?
       <div className={styles.list_box_contont}>
       <Spin/>
       </div>
       :
      <TopTale
         className={`inspector-rate-table`}
         dataSource={alarmType==1?trafficeData:alarmType==3?deckData:alarmType==4?zombieData:alarmType==5?sitiveData:alarmType==2?InspectorgReportData:UnknownOrderData}
         style={{height: '100%'}}
         key="index"
         columns={alarmTypecolumns[(alarmType as AlarmType)-1]}
        scroll={{y:484}}
       // pagination={{className:styles.pagelist,defaultCurrent:Number(pageNum), total:pagetotal, onChange:onChange,pageSize:15 }}
       />
      }
       { pagetotal>15&&<Pagination className={styles.pagelist} defaultCurrent={Number(pageNum)} total={pagetotal}  onChange={onChange} pageSize={15} /> }
        
      </div>
      </div>
    </Modal>
  )
})
// 
export default observer(WarningModal);