import { useHistory } from "react-router-dom";
import cookie from "react-cookies";

import Select from "../Select";

import styles from "./index.module.scss";

const System = () => {
  const history = useHistory();
  const options = [{ label: "退出登录", value: "logout" }];
  const userName = localStorage.getItem("userName");

  const handlerChangeSelect = (value: string) => {
    console.log(value);
    switch (value) {
      case "logout":
        localStorage.clear();
        cookie.remove("vue_admin_template_token");
        history.push("/login");
        break;
    }
  };
  return (
    <div className={styles.system}>
      <div>
        <span>您好! </span>
        <span>{userName}</span>
      </div>
      <Select
        dropdownMatchSelectWidth={182}
        options={options}
        onChange={handlerChangeSelect}
      />
    </div>
  );
};

export default System;
