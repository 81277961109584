import React, { useState, useRef } from 'react';
import { useRequest, useInterval, useEventListener } from 'ahooks';
import {BrowserRouter as Router,useHistory} from 'react-router-dom';
import { message } from 'antd';
import Loading from './components/Loading';
import Header, { SetareaCode ,SetAreaCode} from './components/Header'
import Search from './components/Search';
import GridSearch from './components/GridSearch';
import DataPreview from './components/DataPreview';
import MapBar, { MapBarModeType, SetMode, SetThreeDMode, SetZoomLevel } from './components/MapBar';
import StreetViewWindow from './components/StreetViewWindow';
import GridViewWindow from './components/GridViewWindow';
import InfoWindow from './components/InfoWindow';
import CarParkWindow from './components/CarParkWindow';
import PictureWindow from './components/PictureWindow';
// import Timeline from './components/Timeline';
import Blocks from '@/components/Blocks'
import LuBlocks from '@/components/Panel/luzhou/LuBlocks'
import BottomMenu from './components/BottomMenu';
import IframeModal from './components/IframeModal';
import StreetparkView from './components/StreetparkView';
import Monitor from '@/components/Panel/luzhou/Monitor';
import { GetStreetParkInfo, GetGridInfo, GetParkInfo, GetRoadInfo,GetRoadprogram,GetstaffInfo, GetparklotInfo,GetAreaInfo, GetCarParkInfo, GetRoadListByCity } from './services/api';
import './assets/css/preset.css';
import './App.css';
import cityStore from '@/store/city'
import AreaBox from './components/AreaBox';
import userStore from '@/store/user'
import cookie from 'react-cookies'
import System from './components/System';

const App:React.FC<{}> = () => {
  // const [screenWide,setScreenWide] = useState(false);
  // const { data } = useRequest(() => {
  //   fetch('')
  // })
  const [loading,setLoading] = useState<{
    progress: number;
    interval: number | null | undefined;
  }>({
    progress: 0,
    interval: 100
  });
  const [monitorIframe,setMonitorIframe] = useState(false);
  const [workbillIframe,setWorkbillIframe] = useState(false);
  const [serviceIframe,setServiceIframe] = useState(false);
  const[tabelItemUrl,setTabelItemUrl]=useState('');
  const[tabelItemIframe,setTabelItemIframe]=useState(false);
  const [streetWindowModal,setStreetWindowModal] = useState(false);
  const [streetWindowData,setStreetWindowData] = useState({});
  const [carParkWindowModal,setCarParkWindowModal] = useState(false);
  const [carParkWindowData,setCarParkWindowData] = useState({});
  const [gridtWindowModal,setGridWindowModal] = useState(false);
  const [gridtWindowData,setGridWindowData] = useState({});
  const [infoWindowModal,setInfoWindowModal] = useState(false);
  const [infoWindowData,setInfoWindowData] = useState({});
  const [pictureWindowModal,setPictureWindowModal] = useState(false);
  const [zoomLevel,setZoomLevel] = useState(1);
  const [programUrl,setProgramUrl] = useState('');
  const [IsShowMapbar,setIsShowMapbar] = useState('');  

  
  
  const [distance,setDistance] = useState<{
    distance: number;
    lonlat:  number[]
  }>();
  const [numDistance,setNumDistance] = useState(0);
  const [staffprame,setStaffprame] = useState({});
  
  const [mapbarstate,setMapbarstate] =useState(false)
  
  const [pictureWindowSrc,setPictureWindowSrc] = useState<{
    src: string;
    extraData?: object
  }>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const headerRef = useRef<{
    setAreaCode :SetAreaCode
  }>(null);
  const mapBarRef = useRef<{
    threeDMode: boolean;
    mode: MapBarModeType;
    setMode: SetMode;
    setThreeDMode: SetThreeDMode;
    setZoomLevel: SetZoomLevel;
    entermapMode?:number
  }>(null);
  const InfoWindowRef = useRef<{
    program:{}
  }>(null);
  if(monitorIframe){
    setTimeout(() => {
    let url= InfoWindowRef.current?InfoWindowRef.current?.program['programUrls'][0]:''
    setProgramUrl(url)
  }, 500);
  }
  
  const [menu,setMenu] = useState(0);
  const [areaCode,setAreaCode]=useState('510502')
  const sendIframeMessage = ({type,data,times}: {
    type: string;
    data?: any;
    times?:number;
  }) => {
 
    setIsShowMapbar(type)
    iframeRef.current?.contentWindow?.postMessage({ type, data,times }, '*')
  }

  const operateIframeMessage = ({type, data}: {
    type: string;
    data: any;
  }) => {
    switch(type) {
      case 'cityChange': {
        headerRef.current?.setAreaCode(data);
        break;
      }
      case 'searchValid': {
        if(data) {
          mapBarRef?.current?.setMode('');
        }else {
          message.info('无搜索结果')
        }
        
        break;
      }
      case 'modeChange': {
        mapBarRef?.current?.setMode(data);
        if(data === 'streetView') {
          mapBarRef?.current?.setThreeDMode(true);
        }
        break;
      }
      case 'zoomChange': {
        mapBarRef?.current?.setZoomLevel(data);
        setZoomLevel(data)
        break;
      }
      case 'openParkInfoWindow': {
        getParkWindowData(data);
        break;
      }
      /* 街道**/ 
      case 'openRoadInfoWindow': {
        getRoadWindowData(data);
        break;
      }
      /* 停车场**/ 
      case 'openParklotInfoWindow': {
        getParklotWindowData(data);
        break;
      }
      /* 停车场**/ 
      case 'openStaffInfoWindow': {
        //setWorkbillIframe(true);
       // console.log('巡检',data)
        //setStaffprame(data)

        setInfoWindowData(data);
        setInfoWindowModal(true);
        //getStaffWindowData(data);
        break;
      }
      case 'openAreaWindow': {
        getAreaWindowData(data);
        break;
      }
      case 'openCarParkWindow': {
        getCarParkWindowData(data);
        break;
      }
      case 'openStreetWindow': {
        
        getStreetWindowData(data);
        break;
      }
      case 'openGridWindow': {
        getGridWindowData(data);
        break;
      }
      case 'showCarPicture': {
        setPictureWindowModal(true);
        break;
      }
      case 'showWorkbill': {
        setWorkbillIframe(true);
        break;
      }
      case 'roadChange' : {
        //roadSectionId = data
        break;
      }
      case 'distancewindow' : {
           setDistance(data)
           setNumDistance(data.distance)
        break;
      }
      case 'mapbarstate' : {
        let state=data.state
      //  setMapbarstate(state)
        break;
      }
      
      case 'mapComplete': {
        setLoading({
          ...loading,
          progress: 100
        });
        break;
      }
    }
  }


  const getParkWindowData = async (params: {
    parkId: string;
    date: string;
    time: string;
  }) => {
    try {
      const res = await GetParkInfo(params);
      if(res.code===200) {
        setInfoWindowData(res.data);
        setInfoWindowModal(true);
      }
    } catch (error) {}
  }

  /**路段弹窗*/
  const getRoadWindowData = async (params: {
    roadSectionId: string;
    queryTime: string;
  }) => {
    try {
      const res = await GetRoadInfo(params);
     
      if(res.code===200) {
        setInfoWindowData(res.data);
        setInfoWindowModal(true);

      }
    } catch (error) {}
  }
  /**停车场*/
  const getParklotWindowData = async (params: {
    parkId: string;
    queryTime: string;
  }) => {
    try {
      const res = await GetparklotInfo(params);
     
      if(res.code===200) {
        setInfoWindowData(res.data);
        setInfoWindowModal(true);

      }
    } catch (error) {}
  }
  /**停车场*/
  const getStaffWindowData = async (params: {
    eventManageId: string;
    pageNum: string;
    pageSize: string;
  }) => {
    try {
      const res = await GetstaffInfo(params);
     
      if(res.code===200) {
       
        setInfoWindowData(res.data);
        setInfoWindowModal(true);

      }
    } catch (error) {}
  }

  const getAreaWindowData = async (params: {
    areaId: string;
    date: string;
    time: string;
  }) => {
    try {
      const res = await GetAreaInfo(params);
      if(res.code===200) {
        setInfoWindowData(res.data);
        setInfoWindowModal(true);
      }
    } catch (error) {}
  }

  const getCarParkWindowData = async (params: {
    orderNo: string;
    plateNo: string;
  }) => {
    try {
      const res = await GetCarParkInfo(params.orderNo);
      if(res.code===200) {
        setCarParkWindowData({
          ...res.data,
          plateNo: params.plateNo
        });
        setCarParkWindowModal(true);
      }
    } catch (error) {}
  }

  const getStreetWindowData = async (data: any) => {
    try {
      const res = await GetStreetParkInfo({
        roadSectionId: data.roadSectionId,
        berthId: data.berthId,
        queryTime: data.queryTime
      });
      if(res.code===200) {
        setStreetWindowData({
          ...res.data,
          berthId: data.berthId,
          status: data.status
        });
        setStreetWindowModal(true);
      }
    } catch (error) {}
  }

  const getGridWindowData = async (data: any) => {

    try {
      const res = await GetGridInfo(data.subZoneId);
      if(res.code===200) {
        setGridWindowData({
          ...res.data,
          subZoneName: data.subZoneName
        });
        setGridWindowModal(true);
      }
    } catch (error) {}
  }

  const showPicture = (src: string,extraData = {}) => {
    setPictureWindowSrc({
      src,
      extraData
    });
    setPictureWindowModal(true);
  }
  
  const cityChange = (city: {
    areaCode:string,
    coordinates: [number,number];
  }) => {
    cityStore.areaCode=city.areaCode;
    cityStore.coordinates = city.coordinates;
    
    if(city.areaCode) {
     
      if(mapBarRef.current?.mode === '') {
        mapBarRef.current.setMode('default');
       // onMapModeChange('default', true);
      }
      setAreaCode(city.areaCode)
    }else {
      
      // sendIframeMessage({
      //   type: 'cityChange',
      //   data: {
      //     areaCode: ''
      //   }
      // })
    }
    setTimeout(function(){
      onMenuChange(menu,1)
    },500)
  }

  const onSearch = (keyword:string, searchType: number) => {
    if(!keyword) {
      message.info('请输入搜索内容');
    }else {
      mapBarRef.current?.setMode('');
      sendIframeMessage({
        type: 'search',
        data: {
          searchType,
          keyword
        }
      });
    }
  }

  const onGridSearch = (val: string) => {
    sendIframeMessage({
      type: 'sourceMenu',
      data: val
    })
  }

  const onMapModeChange = (mode: MapBarModeType,val:boolean) => {
    sendIframeMessage({
      type: mode,
      data:val,
      times:mapBarRef?.current?.entermapMode
    })
  }

  const onMapSkinChange = (isDefaultSkin: boolean) => {
    sendIframeMessage({
      type: 'skinChange',
      data: isDefaultSkin
    })
  }

  const onMap3DChange = (is3D: boolean) => {
    sendIframeMessage({
      type: '3dChange',
      data: is3D
    })
  }
  const onMapTileLayerChange =(isEarth:boolean)=>{
    sendIframeMessage({
      type: 'layerChange',
      data: isEarth
    })
  }

  const onAlarmFocus = (roadSectionId: string, parkingNo: string) => {
    sendIframeMessage({
      type: 'focusStreet',
      data: {
        roadSectionId,
        parkingNo
      }
    });
    setMenu(0);
    mapBarRef?.current?.setMode('streetView');
    mapBarRef?.current?.setThreeDMode(true);
  }

  const timeChange = ({date,time}: {
    date: string;
    time: string;
  }) => {
    sendIframeMessage({
      type: 'timeChange',
      data: {
        date,
        time
      }
    })
  }
  const onMenuChange = (index: number,change?:number) => {
    console.log(index)
    if(menu !== index||change) {
      setMenu(index);
      
      switch(index) {
        case 0: {
          
          //街景模式视角随模式切换单独聚焦
          // if(mapBarRef?.current?.mode !== 'streetView') {
          //   sendIframeMessage({
          //     type: '3dChange',
          //     data: mapBarRef?.current?.threeDMode
          //   });
          // }
       if(change){
        //console.log('当前切换两个位置',index,menu)

        setMapbarstate(true)
        setTimeout(function(){
        onMapModeChange('setdefault', true);
        },500)
        // onMapModeChange('parklot', true);
        // onMapModeChange('heatMap', true);
      }else{
          sendIframeMessage({
            type: 'manageMenu'
          });
      }
     
          
          break;
        }
        case 1: {
         /* sendIframeMessage({
            type: '3dChange',
            data: false
          });*/
          //'parklotRate'|'parklotincome'|'roadRate'|'roadincome'
          //  onMapModeChange('parklotincome', true);
          //  onMapModeChange('heatMap', true);
          //  onMapModeChange('roadincome', true);
          sendIframeMessage({
            type: 'sourceMenu'
          });
          
          break;
        }
        case 2: {
          
          // onMapModeChange('alarm', true);
          // onMapModeChange('parklot', false);
          //  onMapModeChange('heatMap', false);
          //  onMapModeChange('default', false);
          sendIframeMessage({
            type: 'monitorMenu'//alarm monitorMenu
          });
          break;
        }
        case 3: {
          // onMapModeChange('parklotRate', true);
          // onMapModeChange('heatMap', true);
          // onMapModeChange('roadRate', true);
          sendIframeMessage({
            type: 'trendMenu'
          });
          break;
        }
        case 4: {
          
          sendIframeMessage({
            type: 'manageMenu'
          })
        }
      }
    }
  }

  useInterval(() => {
    if(loading.progress < 99) {
      setLoading({
        ...loading,
        progress: loading.progress + 0.5
      });
    }else {
      setLoading({
        ...loading,
        interval: null
      })
    }
  },loading.interval)

  useEventListener('message', (e: MessageEvent) => {
    operateIframeMessage(e.data);
  })

  
  /**
   * 页面地址
   */
   const onTabelItemUrlChange = (iframeUrl: string) => {
     if(iframeUrl){
      setTabelItemIframe(true);
      setTabelItemUrl(iframeUrl);
     }
   }

  let  token=cookie.load('vue_admin_template_token')
   const history = useHistory();
   if(!token){
    history.push('/login')
   }
  const mapUrl = process.env.MAP_URL ||"https://www.thingjs.com/s/39fbf7f41b17d0da6f69a3bb"//'https://www.thingjs.com/s/33f67cee8ee127ecf140c115?params=105b0f77fd24654d4eebc434e9' //'https://www.thingjs.com/s/49eb09562fc8b5de8192091f?params=105b0f77fd24654d4eebc434e9'//
   

  return (
    <div className="app app_sm">
      { <iframe src={mapUrl} id="thing-map" title="3dmap" ref={iframeRef}></iframe> }
      {
        loading.progress < 100 ? <Loading progress={loading.progress} /> : <>
        <div className='header'>
          <Header
            ref={headerRef}
            onCityChange={cityChange}
            // onScreenChange={() => setScreenWide(!screenWide)}
            // screenWide={screenWide}
           // menu === 0 &&
          />
          <div className='search_bg'>
            {  <Search onSearch={onSearch} /> }
            </div>
          </div>

           {/* { menu === 1 && <GridSearch onChange={onGridSearch} /> } */}
           {/* 导航总计*/}
           <DataPreview onAlarmFocus={onAlarmFocus} menu={menu} areaCode={cityStore.areaCode} onOpenWorkbill={() => setWorkbillIframe(true)} />
            
          <MapBar menu={menu} onModeChange={onMapModeChange} IsShowMapbar={IsShowMapbar} onMapbarstate={mapbarstate} onmodedistance={distance} onSkinChange={onMapSkinChange} on3DChange={onMap3DChange} sendIframeMessage={sendIframeMessage}  onTileLayerChange={onMapTileLayerChange} ref={mapBarRef}  />
          {/* tab 切换按钮*/}
          <StreetViewWindow visible={streetWindowModal} data={streetWindowData} onCancel={() => setStreetWindowModal(false)} onShowPicture={(src: string, extra: object) => showPicture(src,extra)} />
          {/* 城市街道*/}
          <CarParkWindow visible={carParkWindowModal} data={carParkWindowData} onCancel={() => setCarParkWindowModal(false)} onShowPicture={(src: string) => showPicture(src)} />
          {/* 网格*/}
          <GridViewWindow visible={gridtWindowModal} data={gridtWindowData} onCancel={() => setGridWindowModal(false)} />
           {/* 路段弹窗等*/}
          <InfoWindow  visible={infoWindowModal} onOpenService={() => setServiceIframe(true)} onClose={() => setInfoWindowModal(false)} data={infoWindowData} onOpenMonitor={() => setMonitorIframe(true)} onOpenWorkbill={() => setWorkbillIframe(true)} sendIframeMessage={sendIframeMessage}  ref={InfoWindowRef} />
          
          
          <PictureWindow visible={pictureWindowModal} onClose={() => setPictureWindowModal(false)} src={pictureWindowSrc?.src || ''} extraInfo={pictureWindowSrc?.extraData} />
          {/* <Timeline onChange={timeChange} className={menu === 0 ? '' : 'hide'} /> */}
          <BottomMenu onMenuChange={onMenuChange} menu={menu} onOpenService={() => setServiceIframe(true)} />
         
          <LuBlocks areaCode={cityStore.areaCode} type={menu} sendIframeMessage={sendIframeMessage} showPicture={showPicture} onOpenWorkbill={() => setWorkbillIframe(true)} onAlarmFocus={onAlarmFocus}  onTabelItemUrlChange={onTabelItemUrlChange} /> 
          <div className="shadow"></div>
          <IframeModal visible={workbillIframe} data={staffprame}  onCancel={() => setWorkbillIframe(false)} src="https://c.dgjx0769.com/index2?url=deviceAlarm/index" />
          
          {/* 路段监控*/}
          {/* <IframeModal visible={monitorIframe} onCancel={() => setMonitorIframe(false)} src={programUrl} /> */}
          {/*https://open.ys7.com/ezopen/h5/iframe_se?url=ezopen://EAHCKI@open.ys7.com/E64818069/1.hd.live&autoplay=0&audio=1&accessToken=at.70cwmre1de8bidws4720u738az2zidwb-4g32kmonlx-0t9hfab-qpyc29xuy */}
          <IframeModal visible={serviceIframe} onCancel={() => setServiceIframe(false)} src="https://screen.dgjx0769.com/screen/#/e_video" />
          <IframeModal visible={tabelItemIframe} onCancel={() => setTabelItemIframe(false)} src={tabelItemUrl} />
          {/* 区域数据*/}
         {zoomLevel ===4?  <AreaBox ZoomLevel={0}></AreaBox>:''}
         {/* <StreetparkView></StreetparkView> */}
         <Monitor/>
        <System />
         
        </>
      }
    </div>
  )
}

export default App;
