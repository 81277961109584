import userStore from "@/store/user";
import { useEffect, useMemo, useState } from "react";
import jurisdictionIncludeMenuMap from "../jurisdiction-map";

// 根据权限显示menu
export const useJurisdictionShowMenu = () => {
  const [menus, setMenus] = useState<string[]>([]);

  // const localRoleList = localStorage.getItem("roleList")

  // useEffect(() => {
  //   if(!localRoleList) { return }
  //   JSON.parse(localRoleList)?.forEach((item: any) => {
  //     const includeMenus = jurisdictionIncludeMenuMap?.[item.name];
  //     includeMenus && setMenus([...menus, ...includeMenus]);
  //   });
  // }, [localRoleList])

  const localUserMenus = localStorage.getItem("userMenus")
  
  useEffect(() => {
    if(!localUserMenus) { return }
    const cacheMenus: string[] = []
    JSON.parse(localUserMenus).forEach((menu: any) => cacheMenus.push(menu.name))
    setMenus(cacheMenus)

  }, [localUserMenus])
  

  return [...new Set(menus)];
};
