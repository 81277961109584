interface objType {
  [k: string]: any;
}

// 将对象值转成int类型
// 其他类型就原样返回 只处理number类型都转成int类型
export function objValueToInt(payload: objType[]) {
  const arr: objType[] = [];
  payload.map((item) => {
    const obj = {};
    for (const [key, value] of Object.entries(item)) {
      obj[key] =
        typeof value === "number"
          ? parseInt((value as number).toString())
          : value;
    }
    arr.push(obj);
  });
  return arr;
}
