/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ReactNode,forwardRef ,useCallback,useEffect,useImperativeHandle,useState} from 'react';
import { useRequest } from 'ahooks';
import TopTale from '@/components/topTable';
import { Modal } from 'antd';
import styles from './index.module.scss';
import { Image,Spin,Pagination} from 'antd';
import { GetParkLotOrderList} from '@/services/api';


const AlarmModal:React.FC<{
  visible?: boolean;
  onCancel?: () => void;
  data?:{
    parkId?: string;
  }
 
}> = forwardRef(({data,visible,onCancel},ref) => {
  const [pageNum,setPageNum] = useState('1');
  const [pagetotal,setPagetotal] = useState(0);
  const parkId=data?.parkId||''
  const { data:parklotorderData,loading,error,run:list} = useRequest(useCallback(() => {
    return GetParkLotOrderList({
      parkId,
      pageNum,
      pageSize:'15'
    })},[pageNum,parkId]), {
      manual:true,
      formatResult: res =>{
        setPagetotal(res.data.total)
        return res.data.list
       } ,
       onSuccess:(data)=>{}
  })
  
  useEffect(() => {
  visible&&list()
  
 }, [visible,list]);
//  useImperativeHandle(ref, () => ({
   
// }))
// setTimeout(() => {
//   console.log('订单列表',pagetotal,parklotorderData)
// }, 300);

const onChange = (page: any, pageSize: any)=>{
  
  setPageNum(page)
  setTimeout(() => {
    list()
  }, 300);
}
const Typename=(val:number)=>{
  return ({1:'进行中',  3:'待缴费' ,5:'已缴费' }[val]);
}

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className={styles.alarm_modal}
      width={1350}
      footer={null}
      closeIcon={<img src="/img/icon_close.png" alt="" className={styles.alarm_close} />}
      maskStyle={{background: 'transparent'}}
    >
     {
        data?.parkId&&
       <div  className={styles.warn_box}>
         {
      <div className={styles.warn_list_box}>
       <div className={styles.warn_tab_title}>
       停车场订单列表
       </div>
       <div className={styles.warn_tale_box}>
       
       <TopTale
       className="parklot-order-table"
       dataSource={parklotorderData}
       style={{height: '100%'}}
       rowKey="orderNo"
       columns={[
        { title: '序号' ,  render:(text: any,record: any,index: number)=>`${pageNum>'1'?((Number(pageNum)-1)*15)+index+1:index+1}`},
       { title: '订单ID' , dataIndex: 'orderNo'},
       { title: '驶入时间' , dataIndex: 'driveInTime'},
       { title: '驶出时间' , dataIndex: 'driveOutTime'},
       { title: '停车时长' , dataIndex: 'parkTimeLabel'},
       { title: '车牌号' , dataIndex: 'plateNo'},
       { title: '停车场' , dataIndex: 'parkName'},
       { title: '驶入通道' , dataIndex: 'channelInName'},
       { title: '驱离通道' , dataIndex: 'channelOutName'},
       { title: '停车费原费用' , dataIndex: 'preFee'},
       { title: '实际停车费用' , dataIndex: 'totalFee'},
       { title: '状态' , dataIndex: 'orderStatus', render:(text: any,record: any,index: number)=>`${Typename(record.orderStatus)}`},
       { title: '支付时间' , dataIndex: 'payTime'},
      
     ]}
     scroll={{y: 444}}
     />
    
     { pagetotal>10&&<Pagination className={styles.pagelist} defaultCurrent={Number(pageNum)} total={pagetotal}  onChange={onChange} pageSize={10} /> }
    
      </div>
     </div>
     }
    </div>
    }

     

    </Modal>
  )
})

export default AlarmModal;